<template>
  <v-dialog v-model="dialogVisible" max-width="500">
    <v-card outlined rounded>
      <v-card-title class="justify-center">
        <v-avatar class="ma-3" size="100" tile>
          <v-img :src="require('@/assets/' + dialogImage)"></v-img>
        </v-avatar>
      </v-card-title>
      <v-card-title class="justify-center">{{ dialogTitle }}</v-card-title>
      <v-card-text class="text-center">
        <p v-html="dialogText"></p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" small outlined @click="closeDialog">Close</v-btn>
        <v-btn v-if="showVerifyButton" color="primary" small @click="verify">Verify</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    currentUser: {
      type: Object,
      required: true,
    },
    dialogType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialogVisible: true,
    };
  },
  computed: {
    dialogImage() {
      return this.dialogType === 'verify' ? 'warning.png' : 'mailSent.png';
    },
    dialogTitle() {
      return this.dialogType === 'verify' ? 'Verify your account' : 'Email Verification Sent!';
    },
    dialogText() {
      return this.dialogType === 'verify'
        ? `Your account with email: <strong class="cyan--text">${this.currentUser.email}</strong> is still unverified. Please verify your account now.`
        : `An email verification has been sent to <strong>${this.currentUser.email}</strong>. Please check your inbox and verify your email address.`;
    },
    showVerifyButton() {
      return this.dialogType === 'verify';
    },
  },
  watch: {
    dialogType(newType) {
      if (newType) {
        this.dialogVisible = true;
      }
    }
  },
  methods: {
    closeDialog() {
      this.dialogVisible = false;
      if (this.dialogType === 'sent') {
        localStorage.setItem('emailVerificationSent', 'false');
      }
      this.$emit('update:dialogType', ''); // Reset dialogType in parent component
    },
    verify() {
      this.$emit('resend-verification-email');
      this.$router.push("/settings/profile");
      this.closeDialog();
    },
  },
  mounted() {
    this.dialogVisible = !!this.dialogType; // Ensure dialog is visible based on the dialogType prop
  }
};
</script>
