<template>
  <v-container class="container-profile">
    <v-row dense>
      <v-col cols="12">
        <v-card class="pa-3">
          <v-card-subtitle class="text-h6">My Password</v-card-subtitle>
          <v-card-title class="text-h5">Change Password</v-card-title>

          <v-form ref="form" v-model="formValid" @submit.prevent="updatePassword" class="mx-3 mx-md-10">
            <div class="text-subtitle-2 text-medium-emphasis">Current Password</div>
            <v-text-field
              placeholder="Current Password"
              prepend-inner-icon="mdi-lock-outline"
              :append-icon="visible1 ? 'mdi-eye-off' : 'mdi-eye'"
              :type="visible1 ? 'text' : 'password'"
              @click:append="visible1 = !visible1"
              clearable
              v-model="password"
              :rules="fieldRules"
              :density="textFieldDensity"
              required
              :tabindex="1"
              solo
              outlined
              class="mb-2 mb-md-3"
              :success="isFieldValid(password, fieldRules)"
            />
            <div class="text-subtitle-2 text-medium-emphasis">New Password</div>
            <v-text-field
              placeholder="New Password"
              prepend-inner-icon="mdi-lock-outline"
              :append-icon="visible2 ? 'mdi-eye-off' : 'mdi-eye'"
              :type="visible2 ? 'text' : 'password'"
              @click:append="visible2 = !visible2"
              clearable
              v-model.trim="newPassword"
              :rules="fieldRules.concat((v) => !!v && v.length > 5 || 'Minimum of 6 characters')"
              :density="textFieldDensity"
              required
              :tabindex="2"
              solo
              outlined
              class="mb-2 mb-md-3"
              :success="isFieldValid(newPassword, fieldRules.concat((v) => !!v && v.length > 5 || 'Minimum of 6 characters'))"
            />
            <div class="text-subtitle-2 text-medium-emphasis">Confirm Password</div>
            <v-text-field
              placeholder="Confirm Password"
              prepend-inner-icon="mdi-lock-outline"
              :append-icon="visible3 ? 'mdi-eye-off' : 'mdi-eye'"
              :type="visible3 ? 'text' : 'password'"
              @click:append="visible3 = !visible3"
              clearable
              v-model="confirmPassword"
              :rules="fieldRules.concat(() => confirmPassword === newPassword || 'Password must match')"
              :density="textFieldDensity"
              required
              :tabindex="3"
              solo
              outlined
              class="mb-2 mb-md-3"
              :success="isFieldValid(confirmPassword, fieldRules.concat(() => confirmPassword === newPassword || 'Password must match'))"
            />
            <v-card-actions class="d-flex justify-end">
              <v-btn outlined rounded small type="submit" :tabindex="4" :disabled="!formValid">Update Password</v-btn>
            </v-card-actions>
          </v-form>
          <v-snackbar :color="snackColor" v-model="snackbar" :timeout="2000">{{ response }}</v-snackbar>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import firebase from "firebase/app";
import "firebase/auth";

export default {
  props: {
    activeTab: Number,
  },
  computed: {
    ...mapState(["currentUser", "userClaims"]),
    ...mapGetters(["darkTheme"]),
    textFieldDensity() {
      return this.$vuetify.breakpoint.mdAndUp ? 'compact' : 'comfortable';
    },
  },
  watch: {
    activeTab(newValue, oldValue) {
      if (oldValue === this.tabIndex && newValue !== this.tabIndex) {
        this.resetForm();
      }
    },
  },
  data: () => ({
    tabIndex: 1, // Assuming this is the index of the password tab
    snackbar: false,
    response: "",
    snackColor: "black",

    visible1: false,
    visible2: false,
    visible3: false,

    password: "",
    newPassword: "",
    confirmPassword: "",
    formValid: true,
    fieldRules: [(v) => !!v || "Field is required"],
  }),
  methods: {
    isFieldValid(fieldValue, rules) {
      // Return false if the fieldValue is null, undefined, or an empty string
      if (fieldValue == null || fieldValue === "") {
        return false;
      }

      // Iterate over all the rules and check if the fieldValue satisfies all of them
      return rules.every(rule => rule(fieldValue) === true);
    },
    resetForm() {
      this.password = "";
      this.newPassword = "";
      this.confirmPassword = "";
      this.$refs.form.resetValidation();
    },
    updatePassword() {
      let credential = firebase.auth.EmailAuthProvider.credential(
        this.currentUser.email,
        this.password
      );
      this.currentUser
        .reauthenticateWithCredential(credential)
        .then((auth) => {
          auth.user
            .updatePassword(this.newPassword)
            .then(() => {
              this.snackbar = true;
              this.snackColor = "success";
              this.response = "Password successfully updated!";
              this.resetForm();
            })
            .catch((error) => {
              console.log(error);
              this.snackbar = true;
              this.snackColor = "error";
              this.response = error.message;
            });
        })
        .catch((error) => {
          console.log(error);
          this.snackbar = true;
          this.snackColor = "error";
          this.response = error.message;
        });
    },
  },
};
</script>

<style scoped>
.container-profile {
  max-width: 600px;
  margin: 0 auto;
}

.card-profile {
  padding: 16px;
}

.card-profile-dark {
  background-color: #1e1e1e;
  padding: 16px;
}

@media (max-width: 600px) {
  .container-profile {
    padding: 0 8px;
  }

  .card-profile, .card-profile-dark {
    padding: 12px;
  }

  .mx-3 {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .text-h5 {
    font-size: 20px !important;
  }

  .text-h6 {
    font-size: 18px !important;
  }
}

.mb-2 {
  margin-bottom: 8px !important;
}

.mb-md-3 {
  margin-bottom: 16px !important;
}
</style>
