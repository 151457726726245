<template>
  <v-container class="container-profile" fluid>
    <v-row justify="center">
      <v-col cols="12" md="8" lg="6">
        <v-card class="pa-4 pa-md-5">
          <v-card-subtitle class="text-h6">My Profile</v-card-subtitle>
          <v-card-title class="text-h5">
            {{ displayName }}
          </v-card-title>
          <v-card-subtitle><strong>{{ user.email }}</strong></v-card-subtitle>

          <v-card-actions class="d-flex justify-center justify-md-start">
            <v-btn
              :disabled="show"
              outlined
              rounded
              small
              @click="show=!show"
              class="my-4 my-md-5"
            >
              Edit Profile
            </v-btn>
          </v-card-actions>
          
          <v-expand-transition>
            <div v-show="show" class="ma-4 ma-md-5">
              <v-divider></v-divider>

              <div class="text-subtitle-2 text-medium-emphasis mt-4">Full Name</div>
              <v-text-field
                placeholder="Full Name"
                v-model="editedDisplayName"
                prepend-inner-icon="mdi-account-outline"
                :rules="fieldRules"
                :density="textFieldDensity"
                required
                solo
                outlined
                class="my-3 my-md-4"
                :success="isFieldValid(editedDisplayName)"
              />
              
              <v-card-actions class="d-flex justify-end">
                <v-btn
                  v-if="show"
                  text
                  small
                  @click="resetField"
                  class="mr-4 mb-4"
                >
                  Cancel
                </v-btn>
                <v-btn
                  outlined
                  rounded
                  small
                  @click="updateProf"
                  class="mb-4"
                >
                  Update Profile
                </v-btn>
              </v-card-actions>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar :color="snackColor" v-model="snackbar" :timeout="1000">{{ response }}</v-snackbar>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { auth } from "../../firebaseDb";

export default {
  computed: { 
    ...mapState(["currentUser", "userClaims"]),
    ...mapGetters(["darkTheme"]),
    textFieldDensity() {
      return this.$vuetify.breakpoint.mdAndUp ? 'compact' : 'comfortable';
    },
  },
  data: () => ({
    response: "",
    snackColor: "black",
    snackbar: false,
    formValid: true,
    fieldRules: [(v) => !!v || "Field is required"],
    user: {},
    displayName: "", 
    editedDisplayName: "",
    show: false,
  }),
  created() {
    this.loadUser();
  },
  methods: {
    isFieldValid(fieldValue) {
      return !!fieldValue && fieldValue.length > 0;
    },
    loadUser() {
      this.user = this.currentUser;
      this.displayName = this.user.displayName;
      this.editedDisplayName = this.user.displayName;
    },
    updateProf() {
      auth.currentUser
        .updateProfile({
          displayName: this.editedDisplayName,
        })
        .then(() => {
          this.snackbar = true;
          this.snackColor = "success";
          this.response = "Profile updated!";
          this.displayName = this.editedDisplayName;
          this.loadUser();
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.response = error.message;
        });
      this.resetField();
    },
    resetField() {
      // Reset the editedDisplayName to the original value
      this.editedDisplayName = this.displayName;
      // Hide the form
      this.show = false;
    },
  },
};
</script>

<style scoped>
.container-profile {
  max-width: 100%;
  margin: 0 auto;
}

.card-profile {
  padding: 16px;
}

.card-profile-dark {
  background-color: #1e1e1e;
  padding: 16px;
}

@media (max-width: 600px) {
  .container-profile {
    padding: 0 8px;
  }

  .card-profile, .card-profile-dark {
    padding: 12px;
  }

  .text-h5 {
    font-size: 20px !important;
  }

  .text-h6 {
    font-size: 18px !important;
  }

  .my-4 {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .my-md-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .ma-4 {
    margin: 16px !important;
  }

  .ma-md-5 {
    margin: 20px !important;
  }
}
</style>
