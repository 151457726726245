<template>
  <v-container fluid>
    <v-card style="min-height: 100%;" elevation="0" max-width="1300px" class="mx-auto">
      <v-card-title class="text-center justify-center py-6">
        <h1 class="text-h5">Profile</h1>
      </v-card-title>
      <v-alert
        v-if="currentUser && currentUser.emailVerified === false"
        class="mx-auto col-sm-8 xs-12"
        outlined
        prominent
        type="info"
      >
        <v-row align="center">
          <v-col class="grow">Please verify your account.</v-col>
          <v-col class="shrink">
            <v-btn small rounded color="primary" text @click="handleSendVerificationEmail">Verify</v-btn>
          </v-col>
        </v-row>
      </v-alert>

      <v-tabs
        v-model="activeTab"
        flat
        centered
        show-arrows
        prev-icon="mdi-chevron-left"
        next-icon="mdi-chevron-right"
        active-class="rounded secondary white--text"
        :prev-class="darkTheme ? 'dark-arrow' : ''"
        :next-class="darkTheme ? 'dark-arrow' : ''"
      >
        <v-tab><strong>Profile</strong></v-tab>
        <v-tab><strong>Password</strong></v-tab>
        <v-tab><strong>Subscription</strong></v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <v-card style="max-width: 700px;" class="mx-auto" elevation="0">
            <UserSettingsProfile :userProfile="userProfile" :activeTab="activeTab" />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card style="max-width: 700px;" class="mx-auto" elevation="0">
            <UserSettingsPassword :activeTab="activeTab" />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card style="max-width: 700px;" class="mx-auto" elevation="0">
            <UserSettingsSubscription :activeTab="activeTab" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="snackbarTimeout">
        {{ snackbarMessage }}
      </v-snackbar>
    </v-card>
    
    <VerificationDialog :currentUser="currentUser" :dialogType.sync="dialogType" @resend-verification-email="handleSendVerificationEmail" />
  </v-container>
</template>

<script>
import 'firebase/auth';
import { mapState, mapGetters } from "vuex";
import UserSettingsProfile from "./UserSettingsProfile";
import UserSettingsPassword from "./UserSettingsPassword";
import UserSettingsSubscription from "./UserSettingsSubscription.vue";
import VerificationDialog from '../dialogs/VerificationDialog.vue';
import moment from 'moment';

export default {
  components: {
    UserSettingsProfile,
    UserSettingsPassword,
    UserSettingsSubscription,
    VerificationDialog,
  },
  data() {
    return {
      activeTab: 0,
      snackbar: false,
      snackbarMessage: '',
      snackbarColor: '',
      snackbarTimeout: 6000,
      dialogType: '',
    };
  },
  created() {
    this.$store.dispatch("fetchSettings");
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...mapGetters(['darkTheme']),
  },
  methods: {
    handleSendVerificationEmail() {
      const now = moment();
      const lastSent = localStorage.getItem('lastVerificationEmailSent');
      
      if (lastSent && now.diff(moment(lastSent), 'days') < 1) {
        this.showSnackbar('An email is already sent to your email address. Check your email to verify.', 'error');
        return;
      }

      this.sendVerificationEmail();
      localStorage.setItem('lastVerificationEmailSent', now.toISOString());
    },
    async sendVerificationEmail() {
      if (this.currentUser && !this.currentUser.emailVerified) {
        try {
          await this.currentUser.sendEmailVerification();
          localStorage.setItem('emailVerificationSent', 'true');
          this.showSnackbar('Verification email sent successfully.', 'success');
          this.dialogType = 'sent';
        } catch (error) {
          this.showSnackbar('Error sending verification email.', 'error');
          console.error('Error sending verification email:', error);
        }
      } else {
        this.showSnackbar('User is already verified or not logged in.', 'error');
      }
    },
    showSnackbar(message, type) {
      this.snackbarMessage = message;
      this.snackbarColor = type === 'error' ? 'red' : 'green';
      this.snackbar = true;
    }
  },
};
</script>
